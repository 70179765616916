class Boat{
	constructor(init_end){

		let _this=this;
		

		this.obj3D=new THREE.Object3D();

		let loadObj;
		let scale=.4;
		let innerObj3D=new THREE.Object3D();
		this.obj3D.add(innerObj3D);
		//this.obj3D.rotation.y=Math.PI*0.5;

		let AxisEuler=new THREE.Quaternion().setFromEuler(new THREE.Euler(-0.25*Math.PI,-0.63*Math.PI,0.3*Math.PI));
		this.obj3D.quaternion.copy(AxisEuler);

		



		//AxisEuler.multiply(new THREE.Quaternion().copy(EARTH.earthObj3D.quaternion));

		//let axis=new THREE.Vector3(AxisEuler.x,AxisEuler.y,AxisEuler.z).normalize();

		//let YQ=new THREE.Quaternion().setFromAxisAngle(axis,Math.PI*0.5);
		//this.obj3D.quaternion.multiply(YQ);

		//console.log(YQ);
		//console.log(this.obj3D.quaternion)
		let guideMesh=new THREE.Mesh(new THREE.SphereBufferGeometry(100,16,8),new THREE.MeshStandardMaterial({color:0xff0000,transparent:true,opacity:0.6}));

		//guideMesh.position.copy(axis).multiplyScalar(EARTH.circleR/0.3);
		
		//EARTH.earthObj3D.add(guideMesh);

		//this.obj3D.quaternion.w+=-0.05*Math.PI;

		let animationMixer;
		let moveUnitTime=0.02;
		let animationClip;
		let clipAction;

		let fbxLoader=new THREE.FBXLoader();
		fbxLoader.addParseEvent(loadFbx);
		fbxLoader.load("fbx/boatTest1.fbx.zip",1,function(){});

		function loadFbx(e){
			loadObj=e;
			//console.log(loadObj);
			loadObj.children[2].castShadow=true;
			loadObj.children[2].receiveShadow=true;
			loadObj.scale.set(scale,scale,scale);
			let distanceFromCenter=.943
			loadObj.position.y=distanceFromCenter*EARTH.circleR/0.3;;
			innerObj3D.add(loadObj);

			let materials=loadObj.children[2].material;

			//console.log(materials);

			$.each(materials,(id,el)=>{
				//console.log(el.name)
				if(el.name=="boatNaka"){
					 el.color=el.color.offsetHSL(0,-0.5,-0.25);
				}else{
					el.color=el.color.offsetHSL(0,0,-0.25);
				}
				
			})


			let worldPosition=loadObj.getWorldPosition(new THREE.Vector3());
			//worldPosition.applyQuaternion( AxisEuler);
			guideMesh.position.copy(worldPosition);
			let axis=new THREE.Vector3().copy(worldPosition).normalize();
			let YQ=new THREE.Quaternion().setFromAxisAngle(axis,Math.PI*-0.1);
			_this.obj3D.quaternion.multiply(YQ);


			//fbx アニメーション

			animationMixer = new THREE.AnimationMixer(loadObj);
			//console.log(animationMixer)
			
			animationClip = loadObj.animations[0];


			clipAction = animationMixer.clipAction(animationClip);

			clipAction.loop=THREE.LoopRepeat;
			
			animationMixer.time=0;

			clipAction.play();


			init_end();

		}

		let speed=new THREE.Vector2();
		let xSpeedUnit=0.01;
		//let ySpeedUnit=0.01;
		let zSpeedUnit=0.005;

		xSpeedUnit*=1.5;
		zSpeedUnit*=1;

		let nowV2,lastV2=new THREE.Vector2(),difV2;

		let updateCount=0;

		this.update=()=>{
			if(loadObj){
				animationMixer.update(moveUnitTime);

				let speedX=0.025*Math.PI*Math.sin(updateCount*xSpeedUnit*0.5);
				let speedZ=0.1*Math.PI*Math.cos(updateCount*zSpeedUnit*0.5);

				nowV2=new THREE.Vector2(speedX,speedZ);
				difV2=lastV2.sub(nowV2).clone().normalize();
				lastV2.copy(nowV2);

				let rotateY=Math.atan2(difV2.x,difV2.y)+Math.PI*0.5;

				let SpeedXQ=new THREE.Quaternion().setFromEuler(new THREE.Euler(speedX,0,0));
				let SpeedYQ=new THREE.Quaternion().setFromEuler(new THREE.Euler(0,rotateY,0));
				let SpeedZQ=new THREE.Quaternion().setFromEuler(new THREE.Euler(0,0,speedZ));


				innerObj3D.quaternion.copy(SpeedXQ.multiply(SpeedZQ)).multiply(SpeedYQ);

				
				updateCount+=1;
			}

		}
		
		

	}
}